.confirmation-page {
    text-align: center;

    .remaining-box {
        margin: 0 auto;
        border-radius: 5px;
        border: 3px solid #008140;
        width: 40%;
        color: #008140;
        font-weight: 600;

        @media (max-width: 800px) {
            font-size: 24px
        }
    }

    .confirmation-text {
        margin: 16px 0px;
    }

    .printLink {
        button {
            color: navy;
        }
    }
}