.sc-deposit-amount-form {
    .form-container {
        max-width: 800px;
    }

    .deposit-info-row {
        display: flex;
        justify-content: space-between;

        svg {
            height: 15px;
            width: 14px;
        }

        @media (max-width: 800px) {
            .grv-hero-numbers--small {
                font-size: 28px;
            }
        }
    }

    #depositHistory:hover {
        cursor: pointer;
    }

    #depAmtInputGroup {
        margin-bottom: 24px;
    }

    #deposit-amount-input {
        padding-left: 35px;
    }

    #depAmtPrefix {
        color: dimgrey;
        display: inline-block;
        position: absolute;
        z-index: 420;
        margin-top: 44px;
        margin-left: 10px;
        padding-right: 8px;
        border-right: solid dimgrey 0.5px;
    }

    #infoModalLink {
        float: right;
        position: relative;
        color: black;
        top: 40px;
        right: 8px;
        z-index: 42;
    }

    #infoModalLink:hover {
        cursor: pointer;
    }

    .button-group {
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
    }
}