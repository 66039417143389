.account-owner-info-form {
    .deposit-info-row {
        display: flex;
        justify-content: space-between;
    }

    .grv-col--lg-5 {
        margin-bottom: 24px;
    }

    @media (max-width: 800px) {
        .grv-hero-numbers--small {
            font-size: 28px;
        }
    }

    .button-group {
        display: flex;
        justify-content: flex-end;

        @media not all and (max-width: 800px) {
            button {
                &:last-child {
                    margin-left: 1rem;
                }
            }
        }

        @media (max-width: 800px) {
            justify-content: center;
            width: 50%;
            margin: auto;

            button {
                margin: 5px;
            }
        }
    }
}