.agent-form {
    .button-group {
        display: flex;
        justify-content: flex-end;
    }

    .input-row {
        @media (max-width: 800px) {
            justify-content: center;
            width: 52%;
            margin-left: auto;
            margin-right: auto;
            flex-wrap: nowrap;
        }
    }
}