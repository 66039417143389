.customer-form {
    .button-group {
        display: flex;
        justify-content: flex-end;
        @media (max-width: 800px) {
            flex-direction: column;
        }
    }
    .ssn-toggle {
        margin-bottom: 24px;
    }
}
