.deposit-summary {
    .deposit-info-row {
        display: flex;
        justify-content: space-between;
    }

    .center {
        display: block;
        text-align: center;
    }

    .button-group {
        display: flex;
        justify-content: flex-end;

        @media not all and (max-width: 800px) {
            button {
                &:last-child {
                    margin-left: 1rem;
                }
            }
        }

        @media (max-width: 800px) {
            justify-content: center;
            width: 50%;
            margin: auto;

            button {
                margin: 5px;
            }
        }
    }

    @media (max-width: 800px) {
        .deposit-amount {
            .grv-hero-numbers__label--small {
                text-align: left;
            }
        }
        .grv-hero-numbers--small {
            font-size: 28px;
        }

        .mobile-hero-text {
            font-size: 28px;
        }
    } 
}