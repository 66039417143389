.sc-footer {
    padding-bottom: 10px;

    #logo {
        height: 40px;
    }

    #copyright {
        display: block;
    }

    .legal-row {
        text-transform: uppercase;
        margin-bottom: 1rem;

        @media (max-width: 800px) {
            flex-wrap: nowrap;
            justify-content: center;
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    #logos {
        display: flex;
        justify-content: flex-end;
    }

    #fdic {
        display: inline-block;
        background: url('../../fdic.png');
        background-position: 0 144px;
        background-size: cover;
        height: 26px;
        width: 43px;
    }

    #fdic:hover {
        background-position: 0 174px;
    }

    #eqHousing {
        height: 30px;
        margin-left: 1.5rem;
    }
}