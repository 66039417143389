.divider {
    text-align: center;
    margin: 30px 0 -18px 0;

    img {
        height: 17px;
        position: relative;
        top: -33px;
        padding: 0 20px;
        background: white;
    }
}