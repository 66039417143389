.content {
    text-align: center;

    img {
        width: 50%;
    }

    .green {
        color: green;
    }

    .blue {
        color: blue;
    }
}

.dialog {
    @media (max-width: 800px) {
        .grv-dialog__container {
            min-height: 75vh;
            height: 60%;
            box-shadow: 0 4px 16px 0 rgba(0,0,0,0.2);
        }
    }
}