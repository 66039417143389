.sc-deposit-account-form {
    .accountHelpLink {
        float: right;
        position: relative;
        color: black;
        top: 40px;
        right: 8px;
        z-index: 42;
    }

    .accountHelpLink:hover {
        cursor: pointer;
    }

    @media (max-width: 800px) {
        .grv-hero-numbers--small {
            font-size: 28px;
        }
    }

    .button-group {
        display: flex;
        justify-content: flex-end;

        @media not all and (max-width: 800px) {
            button {
                &:last-child {
                    margin-left: 1rem;
                }
            }
        }

        @media (max-width: 800px) {
            justify-content: center;
            width: 50%;
            margin: auto;

            button {
                margin: 5px;
            }
        }
    }
}