.timed-out {
    margin-top: 15%;

    .left-info {
        text-align: center;
    }

    .right-info {
        border-left: solid gray 2px;
        padding-left: 40px;
    }
}