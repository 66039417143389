.grv-dialog__container {
    max-height: 80%;
    min-width: 200px;
    overflow-y: auto;
  
    /*
      Temporary CSS solution (flex) to make Dialog's body scrollable,
      and keep Dialog's header and footer within a viewport.
      TODO: review this after react-ods upgrade
    */
    display: flex;
    flex-direction: column;
  
    /*
      TODO: review this after react-ods upgrade
    */
    .grv-dialog__content {
      flex-grow: 1;
      overflow-y: auto;
    }
}

.grv-accordion {
  .grv-accordion__title {
    padding-right: 30px;
  }
}

.dialog {
  @media (max-width: 800px) {
      .grv-dialog__container {
          min-height: 75vh;
          height: 60%;
          box-shadow: 0 4px 16px 0 rgba(0,0,0,0.2);
      }
  }
}