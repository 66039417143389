.sc-header {
    padding-top: 20px;
    img {
        max-height: 40px;
    }

    .grv-row {
        div {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        div:first-child {
            justify-content: flex-start;
        }
    }

}